<template lang="pug">
.main-wrapper.agendamento-salvar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / 
						b Consulta de Créditos 

		Panel.my-2(:header="`Consulta de Créditos`")
			.p-grid.p-fluid.p-justify-center
					.p-col-6
						BuscarPaciente(ref='buscarPaciente' label='Cliente' @model='assiste' @limpa='limpar' :cancelarPopup='true' @waiting='waitingPaciente = $event' :hideCadastro='true' )

			Panel.my-3(:header="'Crédito pessoa física'" v-if='pessoa_fisica')
				ProgressBar(v-if='waitingPaciente' mode="indeterminate")
				.p-grid.p-fluid.p-align-end.my-2(v-else)
					.p-col-12.p-md-4
						span <b>Nome: {{pessoa_fisica.nm_apelido? pessoa_fisica.nm_apelido : pessoa_fisica.nm_pessoa_fisica}}</b>
					.p-col-12.p-md-3
						span <b>CPF: {{pessoa_fisica.nr_cpf_f}}</b>
					.p-col-12.p-md-3
						span <b>Saldo: {{$root.formatPrice(pessoa_fisica.nr_saldo)}}</b>
					.p-col-12.p-md-2
						span <b>Titular: {{pessoa_fisica.id == titular.id? 'Sim': 'Não'}}</b>
		
			ProgressBar(v-if='waitingList' mode="indeterminate")
			Panel.my-3(:header="'Crédito pessoa jurídica'" v-else-if='list.length != 0')
				DataTable(:value="list")
					Column(headerStyle='width: 5%;' field='cd_empresa' header='Cod.' bodyStyle='text-align: center;')
					Column(headerStyle='width: 50%;' bodyStyle='overflow-wrap: break-word;' field='nm_empresa' header='Estabelecimento')
					Column(headerStyle='width: 10%;' field='ie_titular' header='Titular' bodyStyle='text-align: center;')
						template(#body='props')
							span {{ props.data.ie_titular? 'Sim': 'Não' }}
					Column(headerStyle='width: 15%;' field='nr_saldo' header='Saldo' bodyStyle='text-align: center;')
						template(#body='props')
							span {{ $root.formatPrice(props.data.nr_saldo) }}
		
</template>

<style lang="scss">
	.agendamento-salvar {
		panel-usuario {
			margin-top: 10px;
		}
	}
</style>

<script>
	import Panel from 'primevue/panel'
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import Button from 'primevue/button'
	import Dropdown from 'primevue/dropdown'
	import SelectButton from 'primevue/selectbutton'
	import Calendar from 'v-calendar/lib/components/calendar.umd'
	import BuscarPaciente from './../Paciente/BuscarPaciente'
	import Dialog from 'primevue/dialog'
	import InputMask from 'primevue/inputmask'
	import InputText from 'primevue/inputtext'
	import InputNumber from 'primevue/inputnumber';
	import Card from 'primevue/card'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Tooltip from "primevue/tooltip"
	import OrigemDropdown from "./../OrigemAtendimento/OrigemDropdown"
	import CustomDropdown from "./../CustomComponents/CustomDropdown.vue"

	import { Paciente } from '../../middleware'
	export default {
		
		components: { OrigemDropdown, CustomDropdown, Panel, ProgressBar, Button, SelectButton, Dropdown, InputMask, Column,
			Calendar, ProgressSpinner, BuscarPaciente, Dialog, Card, DataTable, InputText, InputNumber },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				titular: null,
				pessoa_fisica:null,
				waitingPaciente: false,
				waitingList: false
			}
		},
		methods: {
			saldoPaciente(cd_pessoa_fisica) {
				this.waitingList = true
				if(cd_pessoa_fisica){
					Paciente.saldoPaciente(cd_pessoa_fisica, {ie_associado_med_empresa: true}).then((response) => {
						this.waitingList = false
						if (([200, 201]).includes(response.status)) {
							this.list = response.data.saldo_empresa
						}
					});
				}
			},
			assiste(val){
				if(val){
					this.saldoPaciente(this.$refs.buscarPaciente.cd_pessoa_fisica)
					this.titular = this.$refs.buscarPaciente.titular;
					this.pessoa_fisica = this.$refs.buscarPaciente.model
				} 
				else this.limpar()
			},
			limpar(){
					this.titular = null
					this.pessoa_fisica = null
					this.list = []
			}	
		}
	}
</script>